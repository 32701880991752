import React from "react"
import classnames from "classnames"

import css from "./index.module.scss"

const Button = ({
  el = "a",
  // 'primary' | 'secondary' | 'tertiary'
  // tertiary makes the link blend in with the surrounding text graphically
  purpose = "secondary",
  size = "medium", // 'small' | 'medium' | 'large'
  // shrink-wrap will squeeze the horizontal space to fit around the text, instead of having a min-width according to `size`
  shrinkwrap = false,
  // Unstyled will get rid of the visual border around the button (but still keep the clickable area)
  unstyled = false,
  active = false,
  children,
  className,
  to = undefined,
  ...rest
}) => {
  const classes = classnames(
    css.button,
    css[`size-${size}`],
    css[`purpose-${purpose}`],
    shrinkwrap && css.shrinkwrap,
    unstyled && css.unstyled,
    active && css.active,
    className
  )
  const Component = el
  return (
    <Component
      {...rest}
      {...(to && { to: to, activeClassName: css.active })}
      className={classes}
    >
      {children}
    </Component>
  )
}

export default Button
