/* eslint-disable jsx-a11y/heading-has-content, jsx-a11y/alt-text */

import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import Grid from "./src/components/Grid"
import Testimonial from "./src/components/Testimonial"

import css from "./src/templates/case-study/index.module.scss"

// Replaces default components with custom components for MDX pages
const components = {
  p: (props) => <Grid className={css.longTextContainer}><p {...props} /></Grid>,
  h1: (props) => <Grid className={css.longTextContainer}><h1 {...props} /></Grid>,
  h2: (props) => <Grid className={css.longTextContainer}><h2 {...props}/></Grid>,
  h3: (props) => <Grid className={css.longTextContainer}><h3 {...props}/></Grid>,
  h4: (props) => <Grid className={css.longTextContainer}><h4 {...props}/></Grid>,
  ul: (props) => <Grid className={css.longTextContainer}><ul {...props} /></Grid>,
  ol: (props) => <Grid className={css.longTextContainer}><ol {...props} /></Grid>,
  img: (props) => <span className={css.centered}><img {...props} /></span>,
  Testimonial
}
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
)
