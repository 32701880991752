/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require("./src/styles/global.scss")
// Wrap root elements with custom components for MDX pages
const { wrapRootElement } = require("./wrap-root-element.js")
exports.wrapRootElement = wrapRootElement

// This function polyfills the smooth scrolling behaviour on anchor hash click
const bindAnchorSmoothScroll = () => {
  if (!("scrollBehavior" in document.documentElement.style)) {
    window.addEventListener(
      "click",
      (e) => {
        if (e.defaultPrevented) return
        const anchor = e.target
        if (anchor && anchor instanceof HTMLAnchorElement && !!anchor.hash) {
          const target = document.querySelector(anchor.hash)
          if (target) {
            const previousId = target.id
            target.id = "temporary-new-id-to-stop-browser-jumping-on-click"
            // This depends on the smoothscroll-polyfill working
            target.scrollIntoView({
              behaviour: "smooth",
            })
            setTimeout(() => {
              target.id = previousId
            })
          }
        }
      },
      {
        passive: true,
      }
    )
  }
}

// This function delays the intercom script from launching until the user has interacted with the page.
// or otherwise interacted with the page.
const INTERCOM_INTERACTIVE_EVENTS = "touchmove touchstart mousedown mousemove keypress DOMMouseScroll mousewheel MSPointerMove".split(
  " "
)
const initialiseIntercom = () => {
  // These two functions (bootstrapIntercom and loadIntercomScript) are extracted out of the intercom loading snippet
  const bootstrapIntercom = () => {
    const w = window
    const ic = w.Intercom
    if (typeof ic === "function") {
      ic("reattach_activator")
      ic("update", w.intercomSettings)
    } else {
      const i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
    }
  }
  const loadIntercomScript = () => {
    const d = document
    const s = d.createElement("script")
    s.type = "text/javascript"
    s.async = true
    s.src = "https://widget.intercom.io/widget/udx320s0"
    const x = d.getElementsByTagName("script")[0]
    x.parentNode.insertBefore(s, x)
  }

  bootstrapIntercom()

  const showIntercom = () => {
    loadIntercomScript()
    window.Intercom("boot", {
      app_id: "udx320s0",
      custom_launcher_selector: ".intercom_contact_us",
    })
    unbindIntercom()
  }

  const unbindIntercom = () => {
    INTERCOM_INTERACTIVE_EVENTS.forEach((eventName) =>
      window.removeEventListener(eventName, showIntercom, {
        passive: true,
        capture: true,
      })
    )
  }

  const bindIntercom = () => {
    INTERCOM_INTERACTIVE_EVENTS.forEach((eventName) =>
      window.addEventListener(eventName, showIntercom, {
        passive: true,
        capture: true,
      })
    )
  }

  bindIntercom()
}

exports.onClientEntry = async () => {
  // Polyfill
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
  require("smoothscroll-polyfill").polyfill()
  bindAnchorSmoothScroll()
  initialiseIntercom()
}

exports.onRouteUpdate = ({ location }) => {
  if (window.Intercom) window.Intercom("update")
  if (window.fbq) window.fbq("track", "PageView")
  if (window.qp) window.qp("track", "ViewContent")
  if (window.twq) window.twq("track", "PageView")
  if (window.uetq) {
    window.uetq.push("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
    })
  }
  let attempts = 0
  const key = setInterval(() => {
    attempts++
    if (window.__adroll) {
      if (location.pathname === "/" || location.pathname === "/index.html") {
        window.__adroll.record_user({ adroll_segments: "a4916344" })
      } else if (location.pathname.indexOf("/pricing") === 0) {
        window.__adroll.record_user({ adroll_segments: "9dbc0f84" })
      } else if (location.pathname.indexOf("/how-it-works") === 0) {
        window.__adroll.record_user({ adroll_segments: "1670911f" })
      } else if (
        location.pathname.indexOf("/referral-programs/apparel") === 0
      ) {
        window.__adroll.record_user({ adroll_segments: "9f20bd45" })
      } else if (
        location.pathname.indexOf("/referral-programs/gadget-accessories") === 0
      ) {
        window.__adroll.record_user({ adroll_segments: "c29f84ae" })
      } else if (
        location.pathname.indexOf("/referral-programs/software-digital") === 0
      ) {
        window.__adroll.record_user({ adroll_segments: "ed060858" })
      } else if (
        location.pathname.indexOf("/referral-programs/health-wellness") === 0
      ) {
        window.__adroll.record_user({ adroll_segments: "6dde0c85" })
      } else if (
        location.pathname.indexOf("/referral-programs/beauty-fashion") === 0
      ) {
        window.__adroll.record_user({ adroll_segments: "4acfb435" })
      } else if (
        location.pathname.indexOf("/referral-programs/vaporizers-ecigs") === 0
      ) {
        window.__adroll.record_user({ adroll_segments: "a7a54182" })
      } else if (location.pathname.indexOf("/3dcart/setup") === 0) {
        window.__adroll.record_user({ adroll_segments: "2ec55f80" })
      } else if (location.pathname.indexOf("/bigcommerce/setup") === 0) {
        window.__adroll.record_user({ adroll_segments: "f881eb24" })
      } else if (location.pathname.indexOf("/celery/setup") === 0) {
        window.__adroll.record_user({ adroll_segments: "e608cfb3" })
      } else if (location.pathname.indexOf("/magento/setup") === 0) {
        window.__adroll.record_user({ adroll_segments: "f053b24d" })
      } else if (location.pathname.indexOf("/otherplatforms/setup") === 0) {
        window.__adroll.record_user({ adroll_segments: "b9d68b63" })
      } else if (location.pathname.indexOf("/shopify/setup") === 0) {
        window.__adroll.record_user({ adroll_segments: "1f403973" })
      } else if (location.pathname.indexOf("/volusion/setup") === 0) {
        window.__adroll.record_user({ adroll_segments: "21021fc0" })
      } else if (location.pathname.indexOf("/woocommerce/setup") === 0) {
        window.__adroll.record_user({ adroll_segments: "e7943aee" })
      } else if (location.pathname.indexOf("/yahoo/setup") === 0) {
        window.__adroll.record_user({ adroll_segments: "e0cfb935" })
      } else {
        window.__adroll.record_user({ adroll_segments: "1c1e4747" })
      }
      clearInterval(key)
    } else if (attempts > 10) {
      clearInterval(key)
    }
  }, 1000)
}

// Fix for segment-js using the gatsby-plugin-segment-js
exports.wrapPageElement = ({ props }) => {
  const {
    location: { pathname, search, href },
    pageContext: { title = "ReferralCandy" },
  } = props

  // Doing this because document.referrer is only retaining the initial link it stores
  // Hack to use document as global array to store previous links and clear the 1st item after item 3
  document.locations = document.locations || [document.referrer]
  document.locations.push(href)
  document.previous = document.locations[document.locations.length - 2]

  if (document.locations.length > 2) {
    document.locations.shift()
  }

  if (!window.analytics || typeof window.analytics.page !== "function") {
    console.warn("Unable to locate analytics.js")
    return
  }

  window.analytics.page({
    path: pathname,
    referrer: document.previous,
    search: search,
    title: title,
    url: href,
  })
}
