import React, { PureComponent } from "react"

import css from "./index.module.scss"

class GradientBackground extends PureComponent {
  render() {
    return (
      <div className={css.container}>
        <div className={css.background} />
        <div className={css.content}>{this.props.children}</div>
      </div>
    )
  }
}

export default GradientBackground
