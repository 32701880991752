// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-3-dcart-setup-js": () => import("./../../../src/pages/3dcart/setup.js" /* webpackChunkName: "component---src-pages-3-dcart-setup-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-bigcommerce-setup-js": () => import("./../../../src/pages/bigcommerce/setup.js" /* webpackChunkName: "component---src-pages-bigcommerce-setup-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-celery-setup-js": () => import("./../../../src/pages/celery/setup.js" /* webpackChunkName: "component---src-pages-celery-setup-js" */),
  "component---src-pages-email-not-successfully-verified-js": () => import("./../../../src/pages/email/notSuccessfullyVerified.js" /* webpackChunkName: "component---src-pages-email-not-successfully-verified-js" */),
  "component---src-pages-email-successfully-verified-js": () => import("./../../../src/pages/email/successfullyVerified.js" /* webpackChunkName: "component---src-pages-email-successfully-verified-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magento-setup-js": () => import("./../../../src/pages/magento/setup.js" /* webpackChunkName: "component---src-pages-magento-setup-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-otherplatforms-setup-js": () => import("./../../../src/pages/otherplatforms/setup.js" /* webpackChunkName: "component---src-pages-otherplatforms-setup-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-referral-program-cookie-policy-js": () => import("./../../../src/pages/referral-program-cookie-policy.js" /* webpackChunkName: "component---src-pages-referral-program-cookie-policy-js" */),
  "component---src-pages-referral-programs-js": () => import("./../../../src/pages/referral-programs.js" /* webpackChunkName: "component---src-pages-referral-programs-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-setup-js": () => import("./../../../src/pages/setup.js" /* webpackChunkName: "component---src-pages-setup-js" */),
  "component---src-pages-shopify-plus-js": () => import("./../../../src/pages/shopify-plus.js" /* webpackChunkName: "component---src-pages-shopify-plus-js" */),
  "component---src-pages-shopify-setup-js": () => import("./../../../src/pages/shopify/setup.js" /* webpackChunkName: "component---src-pages-shopify-setup-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-volusion-setup-js": () => import("./../../../src/pages/volusion/setup.js" /* webpackChunkName: "component---src-pages-volusion-setup-js" */),
  "component---src-pages-woocommerce-setup-js": () => import("./../../../src/pages/woocommerce/setup.js" /* webpackChunkName: "component---src-pages-woocommerce-setup-js" */),
  "component---src-pages-yahoo-setup-js": () => import("./../../../src/pages/yahoo/setup.js" /* webpackChunkName: "component---src-pages-yahoo-setup-js" */),
  "component---src-templates-case-study-index-js": () => import("./../../../src/templates/case-study/index.js" /* webpackChunkName: "component---src-templates-case-study-index-js" */),
  "component---src-templates-referral-program-index-js": () => import("./../../../src/templates/referral-program/index.js" /* webpackChunkName: "component---src-templates-referral-program-index-js" */)
}

