module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/helpers/typography"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ReferralCandy Website","short_name":"ReferralCandy","start_url":"/","background_color":"#286ffa","theme_color":"#286ffa","display":"minimal-ui","icon":"src/assets/favicon.png","include_favicon":false,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"cb8e34d304f4b89e2efaff757e0dd6eb"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":700,"withWebp":true,"quality":100,"linkImagesToOriginal":false}},"gatsby-remark-smartypants"]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"UYoXNStyJpQ370Jvov4PBhbVvsj04Dse","trackPage":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-15701008-7","UA-15701008-1"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
