import React from "react"
import { Link } from "gatsby"
import Button from "../Button"
import Img from "gatsby-image"
import Grid from "../Grid"
import GradientBackground from "../Sections/GradientBackground"
import css from "./index.module.scss"

const Testimonial = ({ showcase = null, avatar = null, content }) => {
  const { quote, cite, position, url } = content
  return (
    <GradientBackground>
      <Grid className={css.container} centeredClassName={css.centered}>
        {showcase && (
          <div className={css.media}>
            <Img
              fixed={showcase.childImageSharp.fixed}
              alt={`Referral candy - ${position} recommend referralcandy referral program software`}
            />
          </div>
        )}
        <blockquote className={css.quote_block}>
          <p className={css.quote_text}>{quote}</p>
          <footer className={css.source}>
            {avatar && (
              <div className={css.avatar_container}>
                <Img
                  fixed={avatar.childImageSharp.fixed}
                  alt={`Referral candy - ${position} recommend referralcandy referral program software`}
                />
              </div>
            )}
            <cite>
              <p className={css.name}>{cite}</p>
              {position}
            </cite>
          </footer>
          {url && (
            <Button className={css.quote_button} el={Link} to={url}>
              Read Case Study
            </Button>
          )}
        </blockquote>
      </Grid>
    </GradientBackground>
  )
}

export default Testimonial
