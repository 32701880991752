import React from "react"
import classnames from "classnames"

import css from "./index.module.scss"

const Grid = React.forwardRef(
  ({ className, children, centeredClassName = "", ...rest }, ref) => (
    <div ref={ref} className={classnames(css.container, className)} {...rest}>
      <div className={classnames(css.centered, centeredClassName)}>
        {children}
      </div>
    </div>
  )
)

export default Grid
